import React, { useEffect }  from "react";
import "./ServicePage.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Calender from "../../images/calender.png";
import Image2 from "../../images/image2.png";
import Image4 from "../../images/image4.png";
import Image74 from "../../images/image 74.png";
import Footer1 from "../../components/Footer1/Footer1";
import Contact from "../../components/Contact/Contact";
import Bulb from "../../images/bulb.png";
import Home from "../../images/home.png";
import Shopping from "../../images/shopping.png";
import Entertainment from "../../images/entertainment.png";
import Laptop from "../../images/laptop.png";

const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }); 

  return (
    <div className="Service">
      <Navbar />

      <div className="service-page-container">
        <div className="service-page-text" data-aos="fade-right"  data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" >
          <h1 className="fw-semibold lh-base">
            Enjoy quick payday loan spread over a maximum of 24 months.
          </h1>
          <p> Open to public and private sector confirmed salary earners.</p>
          <a href="#contact">
            <button className="service-page-btn">Contact us</button>
          </a>
        </div>
        <div className="service-page-image"data-aos="flip-right"  data-aos-duration="2000">
          <img src={Calender} alt="" />
        </div>
      </div>
      <div className="row p-2 mb-4" id="Sme">
        <div className="col" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"data-aos-duration="3000" >
          <div className="product-img">
            {" "}
            <img src={Image2} alt="" />
          </div>
        </div>
        <div className="col" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"data-aos-duration="3000" >
          <div className="service-product">
          <h1 style={{ color: "#044183" }}>SME Loans</h1>
          <p>
            This product serves the funding requirements of small and medium
            enterprises. We offer term loan and overdraft facility for a maximum
            of one year to support working capital requirements and other
            revenue generating assets to improve the business operations of
            SMEs. Our focus segments are Wholesale & Retail Trade,
            manufacturing, education, etc.
          </p>
          <a href="#contact">
            <button className="loan-btn">Contact us</button>
          </a>
          </div>
        </div>
      </div>
      <div className="container-fluid service-products2 ">
        <div className="row pt-4"  id="coporateloan">
          <div className="col"data-aos="fade-left"data-aos-duration="2000" >
            <div className="product-img">
              <img src={Image4} alt="" />
            </div>
          </div>
          <div className="col"data-aos="fade-left"data-aos-duration="2000" >
          <div className="service-product">

            <h1 style={{ color: "#044183" }}>Coporate Loans</h1>
            <p>
              We provide short-medium term facilities for corporate clients to
              meet funding requirements for working capital, business expansion,
              contract/project etc. Our corporate clients are manufacturers,
              key distributors, contractors and other financial institutions etc.
              We offer financing through term loan, overdraft facility, contract
              finance facility, project finance, LPO financing facility etc.
            </p>
            <a href="#contact">
              <button className="loan-btn">Contact us</button>
            </a>
            </div>

          </div>
        </div>
        <div className="row gy-3 py-3 p-5"data-aos="zoom-out-up"data-aos-duration="2000">
          <h4>Our focus sectors are</h4>
          <div className="col col-md-6 col-lg-4 ">
            <div className="sectors py-3 px-2">
              <div className="sector-img">
                <img src={Image74} alt=""></img>
              </div>
              <div className="sector-info">
                <strong>Oil & Gas-downstream: </strong>We provide funding requirement to major
                importers of PMS, and suppliers of AGO with confirmed sale
                agreements with top-rated off-takers.
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4">
            <div className="sectors py-3">
              <div className="sector-img">
                <img src={Bulb} alt=""></img>
              </div>
              <div className="sector-info">
               <strong> Power:</strong> We fund the setting up of captive natural gas-fired
                electricity plant under a power purchase agreement with
                reputable off-taker.
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4">
            <div className="sectors px-2">
              <div className="sector-img">
                <img src={Home} alt=""></img>
              </div>
              <div className="sector-info">
               <strong>Real Estate:</strong> We partner with reputable construction company to
                fund medium to high-end housing projects in prime locations with
                financially committed off-takers.
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4">
            <div className="sectors py-3 px-2">
              <div className="sector-img px-2">
                <img src={Shopping} alt=""></img>
              </div>
              <div className="sector-info">
                <strong>General Commerce:</strong> We provide short-term funding to wholesale
                traders/major distributors to finance goods and other revenue
                generating assets. We target clients trading in fast moving
                consumers goods.
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4">
            <div className="sectors">
              <div className="sector-img">
                <img src={Entertainment} alt=""></img>
              </div>
              <div className="sector-info">
                <strong>Entertainment:</strong> We partner with major movie producers to fund
                blockbuster films with international streaming agreement.
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-4">
            <div className="sectors ">
              <div className="sector-img px-2">
                <img src={Laptop} alt=""></img>
              </div>
              <div className="sector-info px-2">
                <strong>Education:</strong> We provide short to medium term funding to high-end
                private primary and secondary school, tertiary institution.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-5 mt-3 mb-5" id="salaryloan">
        <div className="col col-md-6 col-lg-6 loan-info" data-aos="fade-up-left" data-aos-duration="2000">
          <h1 style={{ color: "#044188" }}>Salary Loans</h1>
          <p>
            This product provides quick loan to salary earners. The repayment of
            the loan is from the customer’s monthly salary spread over a maximum
            of 24 months. It is open to public and private sector confirmed
            salary earners. Our target clients are public civil servants under
            the Integrated Payroll and Personnel information system,and private
            sector workers under a third-party payrolling company.
          </p>
          <div className="btn-box">
            <a href="#contact">
              <button className="service-page-btn1 ">Contact us</button>
            </a>
           <Link to="/loancalculator">
            <button className="service-page-btn2">Loan calculator</button>
          </Link></div>
        </div>
        <div className="col col-md-6 col-lg-6 loan-image" data-aos="fade-up-right" data-aos-duration="2000">
          <div className="loan-image">
            <img src={Calender} alt="" />
          </div>
        </div>
      </div>
      <Contact />
      <Footer1 />
    </div>
  );
};

export default ServicePage;

import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Frame1 from "../../images/Frame 464.png";
import "./AboutPage.css";
// import Profile1 from "../../images/Profile1.png";
// import Profile2 from "../../images/Profile2.png";
// import Profile3 from "../../images/Profile3.png";
// import Profile4 from "../../images/Profile4.jpg";
import Footer1 from "../../components/Footer1/Footer1";
import Contact from "../../components/Contact/Contact";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {" "}
      <Navbar />
      <div className="container-fluid px-5">
        <div className="row pt-4">
          <div className="col col-sm-12 col-md-6 col-lg-6 about-header rounded-1">
            <div className="about-header  text-center">
              <h1>
                {" "}
                We provide short-medium term funding to clients in various
                business segments.
              </h1>
            </div>
          </div>
          <div className="col col-md-6 col-lg-6 about-image p-0">
            <div className="about-image">
              <img src={Frame1} alt=""></img>
            </div>
          </div>
        </div>
        <div className="about-info">
          <p className="pt-4 p-1" data-aos="fade-left" data-aos-duration="2000">
            WeFinance Solutions Limited is a lending arm of TrustBanc Group. The
            company was incorporated in November 2022 to provide short-medium
            term funding to clients in various business segments. We provide
            funding needs to clients operating actively in the real sector of
            the Nigerian economy. Our competitive edge over other financial
            providers is our prompt credit decision, which enables clients to
            achieve their goals within the desired timeframe.
          </p>
        </div>

        <div
          className="row about-row "
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="col col-sm-12 col-md-4 col-lg-4 ">
            <h3 className="fw-bold">Our core values</h3>
            <p>
              The culture at TrustBanc Group is that of doing things with E. A.
              S. E.
            </p>
            <ul>
              <li>Empathy</li>
              <li>Accountability</li>
              <li>Service Orientation</li>
              <li>Ethics</li>
            </ul>
          </div>
          <div className="col col-sm-12 col-md-4 col-lg-4 ">
            <h3 className="fw-semibold">Our vision</h3>
            <p>
              To be the prefered provider of seamless integrated financial
              solution to our client.
            </p>
          </div>
          <div className="col col-sm-12 col-md-4 col-lg-4 ">
            <h3 className="fw-semibold">Our mission</h3>
            <p>Your lifetime companion for sustainable wealth vision.</p>
          </div>
        </div>
        {/* <div className="container-fluid pt-3 pb-5">
          <h1 className="text-center fw-medium" style={{ color: "#044188" }}>
            Meet our team
          </h1>
          <div className="row pt-4">
            <div className="col col-sm-12 col-md-6 col-lg-4 p-0 ">
              <div className="team-image">
                {" "}
                <img
                  style={{
                    height: "100%",
                    backgroundColor: "rgb(177,177,175)"
                  }}
                  src={Profile1}
                  class=""
                  alt="..."
                ></img>
              </div>
              <div className="card-body">
                <h1>Azeez Badru</h1>
                <span className="d-flex justify-content-center">
                  <p className="" style={{ width: "65%" }}>
                    Group Head, Investment and Portfolio Management
                  </p>
                </span>
              </div>
            </div>
            <div className="col col-sm-12 col-md-6 col-lg-4 p-0">
              <div className="team-image">
                <img
                  style={{ height: "100%" }}
                  src={Profile4}
                  className=""
                  alt="..."
                ></img>
              </div>
              <div className="card-body">
                <h1>Alice Amos</h1>
                <p>Head, Customer Engagement Abuja</p>
              </div>
            </div>
            <div className="col col-sm-12 col-md-6 col-lg-4 p-0">
              <div className="team-image">
                {" "}
                <img
                  style={{ height: "100%" }}
                  src={Profile2}
                  className=""
                  alt="..."
                ></img>
              </div>
              <div className="card-body">
                <h1>Olatunji Akinsanpe</h1>
                <p> Head, SME</p>
              </div>
            </div>

            <div className="col col-sm-12 col-md-6 col-lg-4 p-0">
              <div className="team-image">
                <img
                  style={{ height: "100%" }}
                  src={Profile3}
                  className=""
                  alt="..."
                ></img>
              </div>
              <div className="card-body">
                <h1>Segun Akinyosoye</h1>
                <p>Head, Customer Engagement</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Contact />
      <Footer1 />
    </div>
  );
};

export default AboutPage;

import React from "react";
import "./service.css";
import Image1 from "../../images/piggy.png";
import Home from "../../images/sme.jpeg";
import Coporate from "../../images/image3.png";
import Salary from "../../images/image4.png";
import { HashLink } from "react-router-hash-link";

const Service = () => {
  return (
    <div className="service">
      <div className="container-fluid service-container">
        <div className="row text-light align-items-center servic-card">
          <div
            className="colcol-sm-12 col-md-6 col-lg-6  align-items-center service-card-info"
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <h1>We are your trusted funding partner.</h1>
            <p>
              {" "}
              Start a new business, expand an existing one, or simply need some
              extra cash for personal expenses, our loan options are designed to
              meet your unique requirements.
            </p>
            <a href="#contact">
              <button className="service-btn">Contact us</button>
            </a>
          </div>
          <div
            className="col col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center serice-card-img pt-4 pb-4"
            data-aos="fade-left"
            data-aos-duration="4000"
          >
            <img src={Image1} alt="" />
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 ">
        <h1 className="">Our Services</h1>
        <div className="row g-4 about-row2">
          <div
            className="col col-xs-12 col-sm-12 col-md-6 col-lg-4"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <div className="product-card p-3">
              <div className="product-card-img ">
                <img src={Home} alt=""></img>
              </div>
              <h3>SME Loans</h3>
              <p>
                This product serves the funding requirements of small and medium
                enterprises. We offer long term loan and overdraft facility for
                a maximum of one year....
              </p>
              <HashLink to="/services#Sme">
                {" "}
                <button className="product-btn px-5 py-3">Read More</button>
              </HashLink>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="product-card p-3">
              <div className="product-card-img">
                <img src={Coporate} alt=""></img>
              </div>
              <h3>Corporate Loans</h3>
              <p>
                We provide short-medium term facilities for corporate clients to
                meet funding requirements for working capital, business
                expansion, contract/project, etc...
              </p>
              <HashLink to="/services#coporateloan" className="">
                {" "}
                <button className="product-btn px-5 py-3 ">Read More</button>
              </HashLink>
            </div>
          </div>
          <div
            className="col col-xs-12 col-sm-12 col-md-6 col-lg-4"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <div className="product-card p-3">
              <div className="product-card-img">
                <img src={Salary} alt=""></img>
              </div>
              <h3>Salary Loans</h3>
              <p>
                This product provides quick loan to salary earners. The
                repayment of the loan is from the customer’s monthly salary
                spread over a maximum of 24 months...
              </p>
              <HashLink to={"/services#salaryloan"}>
                <button className="product-btn px-5 py-3">Read More</button>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;

import React from "react";
import { Link } from "react-router-dom";
import "./footer1.css";
import Call from "../../images/call.png";
import Mail from "../../images/mail.png";
import Instagram from "../../images/Instagram.png";
import Twitter from "../../images/twitter.png";
import Linkedin from "../../images/linkedin.png";
import Facebook from "../../images/facebook.png";
import Logo from "../../images/newlogo.jpeg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const Footer1 = () => {
  return (
    <div className="">
      <div className="container footer1">
        <div className="row footer-row">
          <div className="col col-sm-6 col-md-4 col-md-6">
            <div className="footer1">
              <div className="foot-img mb-2">
                <img src={Logo} alt="" className="p-0" />
              </div>
              <h4 className="fw-light">Head Address</h4>

              <ul className="no-bullets">
                <li>163, Sinari Daranijo Street,</li>
                <li>Off Ligali Ayorinde,</li>
                <li>Victoria Island,</li>
                <li>Lagos, Nigeria.</li>
              </ul>
              <h4 className="fw-light">Abuja Office</h4>

              <ul className="no-bullets">
                <li>No 6, Brains and Hammers Estate,</li>
                <li>Apo 3, Ado Bayero,</li>
                <li>Abuja, Nigeria</li>
                <li></li>
              </ul>

              <h4 className="fw-light">Contact Us</h4>
              <ul className="no-bullets">
                <li>07004446147</li>
                <li>support@trustbancgroup.com</li>
              </ul>
              <div className="social-icon">
                <a
                  href="tel:07004446147"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img src={Call} alt="" />
                </a>
                <a
                  href="https://mail.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Mail} alt="" />
                </a>
                <a
                  href="https://instagram.com/trustbancfinancialgroup?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Instagram} alt="" className="insta-icon" />
                </a>
                <a
                  href="https://twitter.com/trustbancgroup?s=21&t=ZHjrl1jeMADQ2febfriVOw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/70898742/admin/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BL6wam5krRz2uao0Sm7I3DA%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img src={Linkedin} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/trustbancfinancialgroup?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Facebook} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col col-sm-6 col-md-4 col-md-6">
            <form
              className="contact-form"
              id="contact"
              action="https://formsubmit.co/support@trustbancgroup.com"
              method="POST"
            >
              <input
                type="hidden"
                name="_subject"
                value="NEW FORM SUBMISSION(WeFinance)"
              />
              <div className="d-flex flex-column">
                <label>Your name</label>
                <input type="text" name="name" required autoComplete="on" />
                <label>Your email</label>
                <input type="email" name="email" required autoComplete="on" />
                <label>Subject</label>
                <input type="text" name="subject" required />
                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="https://wefinanceng.com/thankyou"
                />
              </div>
              <div className="d-flex flex-column">
                <label>Your message</label>
                <textarea rows="5" cols="30" name="message" required></textarea>
                <button className="form-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
            <div className="d-flex justify-content-center">
              <form
                className="sub-form"
                action="https://formsubmit.co/support@trustbancgroup.com"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_subject"
                  value="NEW FORM TO SUBSCRIBE TO NEWS LETTER(WeFinance)"
                />
                <label>Subscribe to our news letter</label>
                <InputGroup className="">
                  <Form.Control
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    type="email"
                    name="email"
                    required
                    autoComplete="on"
                    aria-describedby="basic-addon2"
                  />
                  <Button id="button-addon2" type="submit">
                    Submit
                  </Button>
                </InputGroup>
                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="https://wefinanceng.com/thankyou"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer1-card">
        <div className="container d-flex justify-content-between">
          <h4>Copyright © {new Date().getFullYear()} - TrustBanc WeFinance</h4>
          <div className="footer-links-holder">
            <Link to="/about" className="footer-links mx-5">
              <p>About</p>
            </Link>
            <Link to="/services" className="footer-links mx-5">
              <p>Services</p>
            </Link>
            <Link to="/" className="footer-links" id="contact">
              <p>Contact</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer1;

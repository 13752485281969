import React, { useEffect }  from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Quickloan.css";
import BankName from "../../DB store/BankList";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Contact from "../../components/Contact/Contact";
import Footer1 from "../../components/Footer1/Footer1";

const Quickloan = () => {
  useEffect(() => {
    //This gets all number inputs
    const numberInputs = document.querySelectorAll('input[type="number"]');
    // Prevent default spinner behavior
    numberInputs.forEach(input => {
      input.addEventListener('mousewheel', function(event) {
        this.blur();
      });
    });
  }, []);
  return (
    <div className="loan">
      <Navbar />
      <div className="loan-card">
        <h1>Quick Public Sector Loan</h1>
      </div>
      <div className="container mt-4 p-5 form-body">
        <Form
          action="https://formsubmit.co/WeFinance@trustbancgroup.com"
          method="POST"
          encType="multipart/form-data"
        >
          <Stack gap={3}>
            <h1 className="fs-3">KYC Details</h1>
            <input
              type="hidden"
              name="_subject"
              value="NEW LOAN SUBMISSION(WeFinance)"
            />
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_template" value="table" />
            <input
              type="hidden"
              name="_next"
              value="https://wefinanceng.com/thankyou"
            />
            <Row className="row-gap-3">
              <Col>
                <Form.Control
                  placeholder="Your FirstName"
                  name="Firstname"
                  type="text"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Your MiddleName"
                  name="Middlename"
                  type="text"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  placeholder="Your LastName"
                  name="Lastname"
                  type="text"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Your Email"
                  type="email"
                  name="Email"
                  required
                  autoComplete="on"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  placeholder="Your Telephone"
                  type="number"
                  name="Telephone"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Your NIN"
                  type="number"
                  name="NIN"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  placeholder="Your BVN"
                  type="number"
                  name="Bvn"
                 
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Your IPPIS/Service Number"
                  name="IPPIS/Service Number"
                  required
                />
              </Col>
            </Row>
            <Form.Control
                  placeholder="Your Address"
                  type="text"
                  name="Address"
                  required
                  autoComplete="on"
                />
          </Stack>
          <Stack gap={3}>
            <h1 className="fs-3 pt-3">Loan Form</h1>
            <Row className="row-gap-3">
              <Col xs={12} md={6} lg={6}>
                <Form.Control
                  placeholder="Loan Amount(NGN)"
                  type="number"
                  name="Loan Amount"
                  onKeyDown={(e) => {
                    const isValidInput = /^[0-9\b]+$/.test(e.key) || e.key === 'Backspace';
                    if (!isValidInput) {
                        e.preventDefault();
                    }
                }}
                onWheel={(e) => e.preventDefault()} 
                

                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Purpose of Loan"
                  type="text"
                  name="Purpose of Loan"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="margin-b">
                <Form.Group
                  as={Col}
                  controlId="formGridState"
                  name="Salary-Bank-Account"
                  type="text"
                >
                  <Autocomplete
                    className="my-autocomplete"
                    name="Salary-Bank-Account"
                    type="text"
                    disablePortal
                    id="combo-box-demo"
                    options={BankName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select salary bank account"
                        name="Salary-Bank-Account"
                        type="text"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Control
                  placeholder="Account Number"
                  type="number"
                  name="Account Number"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="margin-b">
                <Form.Control
                  placeholder="Employer"
                  type="text"
                  name="Employer"
                  required
                />
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control
                    id="floatingPasswordCustom"
                    type="date"
                    placeholder="Employment Date"
                    name="Employment Date"
                    required
                  />
                  <label htmlFor="floatingPasswordCustom">
                    Employment Date
                  </label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="margin-b">
                <Form.Control
                  placeholder="Employers Address"
                  type="text"
                  name="Employers Address"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Employers Telephone"
                  type="number"
                  name="Employers Telephone"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="margin-b">
                <Form.Control
                  placeholder="Fullname of Next of Kin"
                  type="text"
                  name="Fullname of Next of Kin"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Relationship with Next of Kin"
                  type="text"
                  name="Relationship with Next of Kin"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="margin-b">
                <Form.Control
                  placeholder="Next of Kin Telephone"
                  type="number"
                  name="Next of Kin Telephone"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Next of Kin Email"
                  type="email"
                  name="Next of Kin email"
                  required
                />
              </Col>
            </Row>
            
            <Form.Control
              placeholder="Next of Kin Address"
              type="text"
              name="Next of Kin Address"
              required
            />
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Passport Photograph:</Form.Label>
                  <Form.Control
                    type="file"
                    name="Passport Photo attachment"
                    accept="image/png, image/jpeg"
                    required
                  />
                </Form.Group>{" "}
              </Col>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Valid Staff ID:</Form.Label>
                  <Form.Control
                    type="file"
                    name="Valid Staff ID attachment"
                    accept="image/png, image/jpeg"
                    required
                  />
                </Form.Group>{" "}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Valid ID:</Form.Label>
                  <Form.Control
                    type="file"
                    name="Valid ID attachment"
                    accept="image/png, image/jpeg"
                    required
                  />
                </Form.Group>{" "}
              </Col>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Recent PaySlip:</Form.Label>
                  <Form.Control
                    type="file"
                    name="Recent PaySlip attachment"
                    accept="image/png, image/jpeg"
                    required
                  />
                </Form.Group>{" "}
              </Col>
            </Row>
            <Form.Check
              type="checkbox"
              id="form-check1"
              label="While submitting, kindly forward your Passport Photograph, Valid ID, Staff ID and payslip to WeFinance@trustbancgroup.com."
              style={{ color: "#daa43f" }}
              required
            />
            <Form.Check
              id="form-check2"
              type="checkbox"
              label="I hereby consent that the information I supplied above are true. I hereby also agree that upon disbursement, the management fee and transfer fee should be deducted from the requested loan that is to be trasferred to my stated salary account and the repayment will be directly deducted from source via applicable Direct Debit Platform."
              required
            />
            <Button type="submit">Apply</Button>
          </Stack>
        </Form>
      </div>

      <Contact />
      <Footer1 />
    </div>
  );
};

export default Quickloan;


const BankList = [
"LINE CARD MANAGEMENT LIMITED",
"LINE CARD MANAGEMENT ",
"AAA FINANCE AND INVESTMENT COMPANY LIMITED",
"AB MICROFINANCE BANK",
"ABBEY MORTGAGE BANK",
"ABOVE ONLY MICROFINANCE BANK",
"ABSU MICROFINANCE BANK",
"ABU MICROFINANCE BANK",
"ABULESOROÂ MICROFINANCEÂ BANK",
"ACCELEREX NETWORK LIMITED",
"ACCESS BANK",
"ACCESS MONEY",
"ACCESS YELLO & BETA",
"ACCESS(DIAMOND) BANK",
"ACCION MICROFINANCE BANK",
"ADDOSSER MICROFINANCE BANK",
"ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
"ADVANS LA FAYETTE  MICROFINANCE BANK",
"AFEKHAFE MICROFINANCE BANK",
"AG MORTGAGE BANK",
"AGOSASA MICROFINANCE BANK",
"AKU MICROFINANCE BANK",
"AKUCHUKWU MICROFINANCE BANK",
"AL-BARAKAH MICROFINANCE BANK",
"ALEKUN MICROFINANCE BANK",
"ALERT MICROFINANCE BANK",
"AL-HAYAT MICROFINANCE BANK",
"ALLWORKERS MICROFINANCE BANK",
"ALPHA KAPITAL MICROFINANCE BANK",
"ALTERNATIVE BANK LIMITED",
"AMAC MICROFINANCE BANK",
"AMEGY MICROFINANCE BANK",
"AMJU UNIQUE MICROFINANCE BANK",
"AMML MICROFINANCE BANK",
"AMUCHA MICROFINANCE BANK",
"APPLE MICROFINANCE BANK",
"ARISE MICROFINANCE BANK",
"ASO SAVINGS",
"ASPIRE MICROFINANCE BANK",
"ASSET MATRIX MICROFINANCE BANK",
"ASTRAPOLARIS MICROFINANCE BANK",
"AUCHI MICROFINANCE BANK",
"AWACASH MICROFINANCE BANK",
"BABURA MICROFINANCE BANK",
"BAINES CREDIT MICROFINANCE BANK",
"BALERA MICROFINANCE BANK",
"BALOGUN GAMBARI MICROFINANCE BANK",
"BANC CORP MICROFINANCE BANK",
"BANKLY(AMPERSAND) MICROFINANCE BANK",
"BAYERO UNIVERSITY MICROFINANCE BANK",
"BC KASH MICROFINANCE BANK",
"BERACHAH MICROFINANCE BANK",
"BESTSTAR MICROFINANCE BANK",
"BOCTRUST MICROFINANCE BANK LIMITED",
"BORGU  MICROFINANCE BANK",
"BOSAK MICROFINANCE BANK",
"BOWEN MICROFINANCE BANK",
"BRANCH INTERNATIONAL FINANCIAL SERVICES",
"BRENT MORTGAGE BANK",
"BRETHREN MICROFINANCE BANK",
"BRIGHTWAY MICROFINANCE BANK",
"BROADVIEW MICROFINANCE BANK",
"Bunkure Microfinance Bank",
"CANAAN MICROFINANCE BANK",
"CAPSTONE MICROFINANCE BANK",
"CARBON",
"CASHBRIDGE MICROFINANCE BANK",
"CASHCONNECT MICROFINANCE BANK",
"CASHRITE MICROFINANCE BANK",
"CATLAND MICROFINANCE BANK",
"CBN",
"CEDAR MICROFINANCE BANK",
"CELLULANT",
"CEMCS MICROFINANCE BANK",
"CHANELLE MICROFINANCE BANK",
"CHIKUM MICROFINANCE BANK",
"CINTRUST MICROFINANCE BANK",
"CIT MICROFINANCE BANK",
"CITI BANK",
"COASTLINE MICROFINANCE BANK",
"CONFIDENCE MICROFINANCE BANK",
"CONSISTENT TRUST MICROFINANCE BANK",
"CONSUMER MICROFINANCE BANK",
"COOP MORTGAGE BANK",
"CORESTEP MICROFINANCE BANK",
"CORONATION MERCHANT BANK",
"COUNTY FINANCE LIMITED",
"COVENANT MICROFINANCE BANK",
"CREDITVILLE MICROFINANCE BANK",
"CRESCENT MICROFINANCE BANK",
"CS ADVANCE FINANCE COMPANY LIMITED",
"CYBERSPACE LIMITED",
"DAL MICROFINANCE BANK",
"DAVODANI  MICROFINANCE BANK",
"DAYLIGHT MICROFINANCE BANK",
"DIGNITY FINANCE AND INVESTMENT LIMITED",
"DIOBU MICROFINANCE BANK",
"DOT MICROFINANCE BANK",
"EAGLE FLIGHT MICROFINANCE BANK",
"EARTHOLEUM",
"E-BARCS MICROFINANCE BANK",
"ECOBANK",
"ECOBANK XPRESS ACCOUNT",
"ECOMOBILE",
"EDFIN MICROFINANCE BANK",
"E-FINANCE",
"EKONDO MICROFINANCE BANK",
"EK-RELIABLE MICROFINANCE BANK",
"EMERALD MICROFINANCE BANK",
"EMPIRE TRUST MICROFINANCE BANK",
"ENCO FINANCE COMPANY LTD",
"ENTERPRISE BANK",
"ESO-E MICROFINANCE BANK",
"eTRANZACT",
"EVANGEL MICROFINANCE BANK",
"EVERGREEN MICROFINANCE BANK",
"EXCELLENTÂ MICROFINANCEÂ BANK",
"EYOWO",
"FairMoney",
"FAST CREDIT LIMITED",
"FAST MICROFINANCE BANK",
"FBNQUEST MERCHANT BANK",
"FCMB MOBILE",
"FEDERAL POLYTECHNIC NEKEDE MICROFINANCE BANK",
"FEDERAL UNIVERSITY DUTSE MICROFINANCE BANK",
"FEDETH MICROFINANCE BANK",
"FEDPOLY NASARAWA MICROFINANCE BANK",
"FETS",
"FEWCHORE FINANCE COMPANY LIMITED",
"FFS MICROFINANCE BANK",
"FIDELITY BANK",
"FIDELITY MOBILE",
"FIDFUND MICROFINANCE Bank",
"FINATRUST MICROFINANCE BANK",
"FINCA MICROFINANCE BANK",
"FIRMUS MICROFINANCE BANK",
"FIRST APPLE LIMITED",
"FIRST BANK OF NIGERIA",
"FIRST CITY MONUMENT BANK",
"FIRST GENERATION MORTGAGE BANK",
"FIRST OPTION MICROFINANCE BANK",
"FIRST ROYAL MICROFINANCE BANK",
"FIRST TRUST MORTGAGE BANK PLC",
"FIRSTMONIE WALLET",
"FLOURISH MICROFINANCE BANK",
"FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED",
"FORESIGHT MICROFINANCE BANK",
"FORTIS MICROFINANCE BANK",
"FORTIS MOBILE",
"FSDH",
"FULLRANGE MICROFINANCE BANK",
"FUTO MICROFINANCE BANK",
"GABASAWA MICROFINANCE BANK",
"GABSYN MICROFINANCE BANK",
"GASHUA MICROFINANCE BANK",
"GATEWAY MORTGAGE BANK",
"GIDAUNIYAR ALHERI MICROFINANCE BANK",
"GIREI MICROFINANACE BANK",
"GLOBAL INITIATIVE MICROFINANCE BANK",
"GLOBUS BANK",
"GLORY MICROFINANCE BANK",
"GMB MICROFINANCE BANK",
"GOMBE MICROFINANCE BANK LIMITED",
"GOODNEWS MICROFINANCE BANK",
"GOWANS MICROFINANCE BANK",
"GREEN ENERGY MICROFINANCE BANK",
"GREENACRES MICROFINANCE BANK LTD",
"GREENBANK MICROFINANCE BANK",
"GREENVILLE MICROFINANCE BANK",
"GREENWICH MERCHANT BANK",
"GROOMING MICROFINANCE BANK",
"GT MOBILE",
"GTBANK PLC",
"GTI MICROFINANCE BANK",
"HAGGAI MORTGAGE BANK LIMITED",
"HALALCREDIT MICROFINANCE BANK",
"HASAL MICROFINANCE BANK",
"HEADWAY MICROFINANCE BANK",
"HEDONMARK",
"HERITAGE BANK",
"HOMEBASE MORTGAGE BANK",
"HOPEPSB",
"IBILE MICROFINANCE BANK",
"IBOLO MICROFINANCE BANK",
"IBOM FADAMA MICROFINANCE BANKÂ ",
"IC GLOBAL MICROFINANCE BANK",
"IJEBU-IFE MICROFINANCE BANK",
"IKENNE MICROFINANCE BANK",
"IKIRE MICROFINANCE BANK",
"ILARO POLY MICROFINANCE BANK",
"ILISAN MICROFINANCE BANK",
"ILORA MICROFINANCE BANK",
"IMO STATE MICROFINANCE BANK",
"IMPERIAL HOMES MORTGAGE BANK",
"INFINITY MICROFINANCE BANK",
"INFINITY TRUST MORTGAGE BANK",
"INNOVECTIVES KESH",
"INTELLIFIN",
"INTERLAND MICROFINANCE BANK",
"INTERSWITCH LIMITED",
"IRL MICROFINANCE BANK",
"ISALEOYO MICROFINANCE BANK",
"ISLAND MICROFINANCE BANK",
"IYE OKIN MICROFINANCE BANK LTD",
"IYIN EKITI MICROFINANCE BANK",
"JAIZ BANK",
"JUBILEE LIFE",
"KADPOLY MICROFINANCE BANK",
"KAYVEE MICROFINANCE BANK",
"KC MICROFINANCE BANK",
"KCMB MICROFINANCE BANK",
"KEGOW(CHAMSMOBILE)",
"KEYSTONE BANK",
"KKU MICROFINANCE BANK",
"KONGAPAY",
"KONTAGORA MICROFINANCE BANK",
"KOPO KOPE MICROFINANCE BANK",
"KREDI MONEY MICROFINANCE BANK",
"KUDA MICROFINANCE BANK",
"LAGOS BUILDING AND INVESTMENT COMPANY",
"LAPO MICROFINANCE BANK",
"LAVENDER MICROFINANCE BANK",
"LEADCITY MICROFINANCE BANK",
"LEADREMIT LIMITED",
"LEGEND MICROFINANCE BANK",
"LETSHEGO MICROFI",
"LIFEGATE MICROFINANCE BANK",
"LINKS MICROFINANCE BANK",
"LIVINGTRUST MORTGAGE BANK PLC",
"LOTUS BANK",
"LOVONUS MICROFINANCE BANK",
"LUKEFIELD FINANCE COMPANY LIMITED",
"M36",
"MAB ALLIANZ MICROFINANCE BANK",
"MABINAS MICROFINANCE BANK",
"MACROD MICROFINANCE BANK LIMITED",
"MADOBI MICROFINANCE BANK",
"MAINLAND MICROFINANCE BANK",
"MAINSTREET MICROFINANCE BANK",
"MALACHY MICROFINANCE BANK",
"MARITIME MICROFINANCE BANK",
"MAYFRESH MORTGAGE BANK",
"MEDEF MICROFINANCE BANK",
"MEGAPRAISE MICROFINANCE BANK",
"MERIDAN MFB",
"MICHAEL OKPARA UNIAGRIC MICROFINANCE BANK",
"MICROCRED MICROFINANCE BANK",
"MICROVIS MICROFINANCE BANK",
"MIDLAND MICROFINANCE BANK",
"MINJIBIR MICROFINANCE BANK",
"MINT-FINEX MFB",
"MKOBO MICROFINANCE BANK",
"MKUDI",
"MOLUSI MICROFINANCE BANK",
"MOMO PAYMENT SERVICE BANK",
"MONEY BOX",
"MONEY TRUST MICROFINANCE BANK",
"Moneymaster PSB",
"MONIEPOINT MICROFINANCE BANK",
"MOZFIN MICROFINANCE BANK",
"MUTUAL BENEFITS MICROFINANCE BANK",
"MUTUAL TRUST MICROFINANCE BANK",
"NAGARTA MICROFINANCE BANK",
"NDIORAH MICROFINANCE BANK",
"NEPTUNE MICROFINANCE BANK",
"NEW DAWN MICROFINANCE BANK",
"NEW GOLDEN PASTURES MICROFINANCE BANK",
"NEW PRUDENTIAL BANK",
"NEXIM BANK",
"NIGERIA PRISONS MICROFINANCE BANK",
"NIGERIAN NAVY MICROFINANCE BANK",
"NIP VIRTUAL BANK",
"NIRSAL NATIONAL MICROFINANCE BANK",
"NNEW WOMEN MICROFINANCE BANK",
"NOVA MERCHANT BANK",
"NOWNOW DIGITAL SYSTEMS LIMITED",
"NPF MICROFINANCE BANK",
"NSEHE MICROFINANCE BANK",
"NWANNEGADI MICROFINANCE BANK",
"OCHE MICROFINANCE BANK",
"ODOAKPU Microfinance Bank",
"OHAFIA MICROFINANCE BANK",
"OHHA MICROFINANCE BANK",
"OKE-ARO OREDEGBE MFB",
"OKENGWE MICROFINANCE BANK",
"OKPOGA MICROFINANCE BANK",
"OKUKU MICROFINANCE BANK",
"OLABISI ONABANJO UNIVERSITY MICROFINANCE",
"OLOWOLAGBA MICROFINANCE BANK",
"OMIYE MICROFINANCE BANK",
"OPAY",
"OPTIMUS BANK",
"OROKAM MICROFINANCE BANK",
"OSCOTECH MICROFINANCE BANK",
"OTECH MICROFINANCE BANK",
"OTUO MICROFINANCE BANK",
"OYAN MICROFINANCE BANK",
"PAGA",
"PAGE MFBank",
"PALMPAY",
"PARALLEX BANK",
"PARKWAY-READYCASH",
"PATRICKGOLD MICROFINANCE BANK",
"PAYATTITUDE ONLINE",
"PAYSTACK PAYMENT LIMITED",
"PEACE MICROFINANCE BANK",
"PECANTRUST MICROFINANCE BANK",
"PENNYWISE MICROFINANCE BANK",
"PERSONAL TRUST MICROFINANCE BANK",
"PETRA MICROFINANCE BANK",
"PILLAR MICROFINANCE BANK",
"PLATINUM MORTGAGE BANK",
"POLARIS BANK",
"POLYUNWANA MICROFINANCE BANK",
"PREMIUM TRUST  BANK",
"PRESTIGE MICROFINANCE BANK",
"PROSPERITY MICROFINANCE BANK",
"PROVIDUSBANK",
"PYRAMID MICROFINANCE BANK",
"QUICKFUND MICROFINANCE BANK",
"RAND MERCHANT BANK",
"RAYYAN MICROFINANCE BANK",
"REFUGE MORTGAGE BANK",
"REGENT MICROFINANCE BANK",
"REHOBOTH MICROFINANCE BANK",
"RELIANCE MICROFINANCE BANK",
"RENMONEY MICROFINANCE BANK",
"REPHIDIM MICROFINANCE BANK",
"RICHWAY MICROFINANCE BANK",
"RIGO MICROFINANCE BANK",
"RIMA GROWTH PATHWAY MICROFINANCE BANK",
"ROCKSHIELDÂ MICROFINANCEÂ BANK",
"ROYAL BLUE MICROFINANCE BANK",
"ROYAL EXCHANGE MICROFINANCE BANK",
"RUBIES MICROFINANCE BANK",
"SAFE HAVEN MICROFINANCE BANK",
"SAFEGATE MICROFINANCE BANK",
"SAFETRUST",
"SAGAMU MICROFINANCE BANK",
"SAGE GREY FINANCE LIMITEDÂ ",
"SEAP MICROFINANCE BANK",
"SEEDVEST MICROFINANCE BANK",
"SHALOM MICROFINANCE BANK",
"SHERPERD TRUST MICROFINANCE BANK",
"SHIELD MICROFINANCE BANK",
"SHONGOM MICROFINANCE BANK",
"SIGNATURE BANK",
"SIMPLE FINANCE LIMITED",
"SLS MICROFINANCE BANK",
"SMARTCASH PAYMENT SERVICE BANK",
"SOLID ALLIANZE MICROFINANCE BANK",
"SOLIDROCK MICROFINANCE BANK",
"SOURCE MICROFINANCE BANK",
"SPARKLE",
"SPECTRUM MICROFINANCE BANK",
"STANBIC IBTC @Ease WALLET",
"STANBIC IBTC BANK",
"STANDARD CHARTERED BANK",
"STANFORD MICROFINANCE BANK",
"Stateside MFB",
"STELLAS MICROFINANCE BANK",
"STERLING BANK",
"STERLING MOBILE",
"SULSPAP MICROFINANCE BANK",
"SUNTOP MICROFINANCE",
"SUNTRUST BANK",
"SUPPORT MICROFINANCE BANK",
"SUPREME MICROFINANCE BANK",
"TAGPAY",
"TAJ BANK",
"TANADI MFB (CRUST)",
"TANGALE MICROFINANCE BANK",
"TANGERINE MONEY",
"TCF MICROFINANCE BANK",
"TEAMAPT LIMITED",
"TEASY MOBILE",
"TEKLA FACTORING AND FINANCE LIMITED",
"THINK FINANCE MICROFINANCE BANK",
"TITAN TRUST BANK",
"TITAN-PAYSTACK",
"TOTAL TRUST MICROFINANCE BANK",
"TRIDENT MICROFINANCE BANK",
"TRINITY FINANCE",
"TRIPLEA MICROFINANCE BANK",
"TRUST MICROFINANCE BANK",
"TRUSTBANC J6 MICROFINANCE BANK LIMITED",
"TRUSTBOND MORTGAGE BANK",
"TRUSTFUND MICROFINANCE BANK",
"U & C MICROFINANCE BANK",
"UDA MICROFINANCE BANK",
"UHURU MICROFINANCE BANK",
"UMMAH MICROFINANCE BANK",
"UMUCHUKWU MICROFINANCE BANK",
"UNAAB MICROFINANCE BANK",
"UNIBEN MICROFINANCE BANK",
"UNICAL MICROFINANCE BANK",
"UNIFUND MICROFINANCE BANK LIMITED",
"UNILORIN MICROFINANCE BANK",
"UNION BANK",
"UNITED BANK FOR AFRICA",
"UNITY BANK",
"UNIVERSITY OF NIGERIA, NSUKKA MICROFINANCE BANK",
"UZONDU MICROFINANCE BANK",
"VALE FINANCE LIMITED",
"VENTURE GARDEN NIGERIA LIMITED",
"VFD MFB",
"VISA MICROFINANCE BANK",
"VT NETWORKS",
"Waya Microfinance Bank",
"WEMA BANK",
"WETLAND  MICROFINANCE BANK",
"Xpresswallet",
"XSLNCE MICROFINANCE BANK",
"YCT MICROFINANCE BANK",
"YES MICROFINANCE BANK",
"YOBE MICROFINANCE  BANK",
"ZENITH BANK",
"ZENITH EASY WALLET",
"ZENITH MOBILE",
"ZIKORA MICROFINANCE BANK",
"FORTRESS MICROFINANCE BANK",
];
  
export default BankList;

import React from "react";
import Call from "../../images/contact.png";
import "./contact.css";

const Contact = () => {
  return (
    <div>
      <div className="container p-0">
        <div className="row gx-0 about-row2">
          <div className="col col-xs-6 col-sm-12 col-md-6 col-lg-6">
            <div className="contact-text">
              {" "}
              <p>
                Get flexible terms, competitive rates, and exceptional customer
                service. <br />
                <br />
                <br />
                We strive to make your borrowing experience hassle-free and
                convenient.
              </p>
              <a href="#contact">
                {" "}
                <button className="contact-btn">Contact Us</button>
              </a>{" "}
            </div>
          </div>
          <div className="col col-xs-6 col-sm-12 col-md-6 col-lg-6">
            <img src={Call} alt="" className="contact-img"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

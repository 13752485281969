import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./calculator.css";
import Contact from "../../components/Contact/Contact";
import Footer1 from "../../components/Footer1/Footer1";


const Calculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const [num1, setNum1] = useState(0);
  //   const [num2, setNum2] = useState(0);
  const [num3, setNum3] = useState(0);
  const [Add, setAdd] = useState(0);
  const [monthlySum, setMonthlySum] = useState(0);

  const calcSum = (e) => {
    e.preventDefault();
    console.log(e);

    if (num1 === 0 || num3 === 0) {
      alert("please enter a valid Amount");
    } else {
      let Add =
        (parseInt(num1) * parseInt(num3) * parseInt(3)) / 100 + parseInt(num1);
      console.log(typeof Add);
      setAdd(parseInt(Add));

      if (num1 === 0 || num3 === 0) {
        alert("please enter a valid Amount");
      } else {
        let monthlySum = parseInt(Add) / parseInt(num3);
        console.log(typeof monthlySum);
        setMonthlySum(parseInt(monthlySum));
      }
    }
    
  };

  const handleClick = (e) => {
    window.location.reload();
  };

  return (
    <div>
      <Navbar />
      <div className="calculator-card">
        <h1>Loan Calculator</h1>
      </div>
      <div className="calculator-body mt-5 mb-5">
        <div className="calculator-body2">
          <div className="calculator-text px-5 pt-4">
            <h1>Loan Calculator</h1>
            <p>Calculate your Loan Amount</p>
          </div>
          <div className="amount-card">
            <div className="amount">
              <h3>₦ {Add.toLocaleString()}</h3>
              <p>Total payment</p>
            </div>
            <div className="amount">
              <h3>₦ {monthlySum.toLocaleString()}</h3>
              <p>Monthly payment</p>
            </div>
          </div>
          <form className="cal-form" onSubmit={calcSum}>
            <div className="container px-5">
              <div className="form-floating mb-3 mt-3">
                <input
                  type="number"
                  min="0"
                  value={num1 || ""}
                  onChange={(e) => {
                    setNum1(e.target.value);
            
                  }}                  className="form-control"
                  id="floatingInput"
                  placeholder="Amount"

                />
                <label htmlFor="floatingInput">Loan Amount</label>
              </div>
              <div className="form-floating">
                <input
                  type="number"
                  min="0"
                  // value={num2 || ""}
                  // onChange={(e) => setNum2(e.target.value)}
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Interest Rate by 3%"
                  disabled
                />
                <label htmlFor="floatingPassword">Interest Rate by 3%</label>
              </div>
              <div className="form-floating mt-3">
                <input
                  type="number"
                  min="0"
                  value={num3 || ""}
                  onChange={(e) => setNum3(e.target.value)}
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Tenor by month"
                />
                <label htmlFor="floatingPassword">Loan Tenor (by month)</label>
              </div>

              <div className="row mt-5 pt-3">
                <div className="col">
                  <button type="" className="cal-btn1">
                    Calculate
                  </button>{" "}
                </div>
                <div className="col">
                  <button
                    type="submit"
                    className="cal-btn2"
                    onClick={handleClick}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Contact />
      <Footer1 />
    </div>
  );
};

export default Calculator;

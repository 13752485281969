import "./App.css";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer1 from "./components/Footer1/Footer1";
import Navbar from "./components/Navbar/Navbar";
import Service from "./components/Services/Service";
import "aos/dist/aos.css";

function App() {
  return (
    <div>
      <Navbar/>
      <Service/>
      <About/>
      <Contact/>
      <Footer1/>
    </div>
  );
}

export default App;

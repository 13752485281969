import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";
import Contact from "../../components/Contact/Contact";
import Footer1 from "../../components/Footer1/Footer1";

const Faq = () => {
  return (
    <div>
      <Navbar />
      <div className="faq-card">
        <h1>FAQS</h1>
      </div>
      <div className="row px-4 mb-5 faq-position">
        <div
          className="col col-xs-12 col-sm-12 col-md-6 col-lg-6 accordion1 pt-3"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                What are the requirements for your loan?
              </Accordion.Header>
              <Accordion.Body>
                <strong> Corporate Loan</strong>
                <ul>
                  <li>Business registration documents</li>
                  <li>Company profile</li>
                  <li>Sales record</li>
                  <li>Bank statement</li>
                  <li>Valid ID</li>
                  <li>Utility bill</li>
                  <li> Passport photograph</li>
                </ul>
                <strong> Salary Loan</strong>
                <ul>
                  <li>Onboarding form</li>
                  <li>Staff ID card</li>
                  <li>
                    Valid means of identification (Driver’s License, National ID
                    card, International Passport, Voters card)
                  </li>
                  <li> Passport photograph</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Who are those eligible for the loan?
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    Small and Medium Enterprises that engages in wholesale
                    trading of fast moving consumer goods.
                    <strong>(Corporate Loans)</strong>
                    <br />
                    <br />
                    <li>
                      We offer personal loans to private and public sector
                      employees.<strong>(Salary Loans)</strong>
                    </li>
                  </li>
                </ul>{" "}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How do I repay the loan?</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    The repayment mode is usually monthly. However, it can be
                    modelled based on the customer’s cash conversion cycle.{" "}
                    <strong>(Salary Loan)</strong>
                  </li>
                  <br />
                  <li>
                    The repayment mode is in line with the customer’s cash
                    conversion cycle. <strong>(Coporate Loan)</strong>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>What is the loan pricing?</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    The loan pricing comprises an interest rate of 28% per annum
                    and an upfront fee of 2% of the facility amount.{" "}
                    <strong>(Coporate Loan)</strong></li>
                    <br />
                    <li>The loan pricing comprises an interest rate of 3% flat per month
                    and an upfront fee of 3.75% of the facility amount.<strong>(Salary Loan)</strong> </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div
          className="col col-xs-12 col-sm-12 col-md-6 col-lg-6 accordion1 pt-3"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="4">
              <Accordion.Header>What is the repayment period?</Accordion.Header>
              <Accordion.Body> <ul>
                <li>
                A minimum of 30 days and a maximum of 24months subject to the
                customer’s cashflow capacity.<strong>(Salary Loan)</strong></li>
                <br/>
                <li>In line with the customer’s capacity. <strong>(Coporate Loan)</strong> </li>

               </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                What are the collateral for the loan?
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    Legal mortgage on property in a prime location with Forced
                    Sales Value covering at least 140% of the facility amount.<strong>(Coporate Loan)</strong>
                  </li>
                  <br/>
                  <li>
                    No collateral is needed for this loan process.
                    <strong>(Salalry Loan)</strong>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                What is your minimum and maximum loan amount.
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    The minimum loan amount is ₦5million and the maximum loan
                    amount is ₦100million subject to the customer’s capacity.
                    <strong>(Coporate Loan)</strong>
                  </li>
                  <br />
                  <li>
                    You can get a loan as little as ₦100,000 to ₦5million.
                    <strong>(Salary Loan)</strong>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How long does it take to get money?
              </Accordion.Header>
              <Accordion.Body>
                24 hours after meeting all conditions precedent to drawdown.<strong>(Salary Loan)</strong>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Is there any penalty to liquidate my loan?{" "}
              </Accordion.Header>
              <Accordion.Body>
                You can liquidate your loan at any time with no penalty charges.<strong>(Salary Loan)</strong>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <Contact />
      <Footer1 />
    </div>
  );
};

export default Faq;

import React from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  return (
    <div className="nav-body">
      <nav className="navbar navbar-expand-lg  px-3 ">
        <Link  to="/">
          <img src={Logo} alt="" className="nav-logo"/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end " id="navbarNavDropdown" >
          <ul className="navbar-nav d-flex justify-content-evenly">
            <li>
              <Link to="/" className="nav-link ">
             Home
              </Link>
            </li>
            <li>
              <Link to="/about"  className="nav-link">
                About
              </Link>
            </li>
            <li>
              <Link to="/services"  className="nav-link">
                Our Services
              </Link>
            </li>
            <li>
              <Link to="/faq"  className="nav-link">
               FAQs
              </Link>
            </li>
            <li>
            <Link to="/quickloan"  className="nav-link">
             Quick Public Sector Loan
              </Link>
            </li>
            {/* <li>
            <Link to= "/loancalculator">
                <button className="nav-btn ">Loan Calculator</button>
           </Link>
            </li> */}
            <li>
            <a href="#contact">
                <button className="nav-btn">Contact us</button>
           </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;

import React from "react";
import Building from "../../images/Frame 458.png";
import "./About.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="container-fluid p-0">
      <div className="row p-5 about-row2" >
        <div className="col col-xs-12 col-sm-12 col-md-6 col-lg-6" data-aos="fade-up"
     data-aos-duration="4000">
          <div className="about-card1">
            <h3>Who We Are</h3>
            <p>
              WeFinance Solution Limited was established in November 2022 to
              funding services to corporate, retails, and individuals. WeFinance
              is a subsidiary ( lending arm) of TrustBanc Holdings LTD.
            </p>
          <Link to="/about">  <button className="about-btn">Read More</button></Link>
          </div>
        </div>
        <div className="col col-xs-12 col-sm-12 col-md-6 col-lg-6" data-aos="fade-up "
     data-aos-duration="4000">
          <div className="about-card2 ">
            {" "}
            <img src={Building} alt=""></img>
          </div>
        </div>
      </div>
      <div className="container-fluid px-5">
      <div className="row values" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" >
        <div className="col col-sm-12 col-md-4 col-lg-4 ">
          <h3 className="fw-bold">Our core values</h3>
          <p>
            The culture at TrustBanc Group is that of doing things with E. A. S.
            E.
          </p>
          <ul>
            <li>Empathy</li>
            <li>Accountability</li>
            <li>Service Orientation</li>
            <li>Ethics</li>
          </ul>
        </div>
        <div className="col col-sm-12 col-md-4 col-lg-4 ">
          <h3 className="fw-semibold">Our vision</h3>
          <p>
            To be the prefered provider of seamless integrated financial
            solution to our client.
          </p>
        </div>
        <div className="col col-sm-12 col-md-4 col-lg-4 ">
          <h3 className="fw-semibold">Our mission</h3>
          <p>Your lifetime companion for sustainable wealth vision.</p>
        </div>
      </div></div>
      <div className="about-bg d-flex justify-content-around">
        <h3 className="text-light" data-aos="fade-right"data-aos-duration="3000">
          Get information on your payday loan duration.
        </h3>
        <Link to="/loancalculator">
        <button className="about-cal" data-aos="fade-right"data-aos-duration="3000">Use Loan Calculator</button>
      </Link></div>
    </div>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";
import "./Thanks.css";

const Thanks = () => {
  return <div className="page-container d-flex justify-content-center align-items-center">
    <div className="main">
        <h1>☺</h1>
    <h2>Thank You</h2>
          <p>This form has been submited <Link to="/"> Click Here</Link> to go back to homepage</p>
    </div>
  </div>;
};

export default Thanks;
